<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="pull-left">
              <h4 class="card-title" style="text-align: center">Foydalanuvchilar ro'yxati</h4>
            </div>
            <div class="pull-right">
              <div class="input-group search-box">
                <input class="form-control" type="text" :placeholder="'Izlash...'" v-model="filter.search" @keypress.enter="initList">
                <div class="input-group-append">
                  <button class="btn btn-info" @click="initList">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th class="app-w-20p">Id</th>
                <th class="app-w-30p">F.I.SH</th>
                <th class="app-w-10p">Login</th>
                <th class="app-w-5p">Holati</th>
                <th class="app-w-15p d-none d-md-table-cell">Qo'shilgan vaqti</th>
                <th class="app-w-15p d-none d-md-table-cell">O'zgartirilgan vaqti</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading && results && results.data">
              <tr v-for="result in results.data" :key="result.id">
                <td>{{ result.id }}</td>
                <td>{{ result.fullName }}</td>
                <td>{{ result.username }}</td>
                <td class="text-center">
                  <span v-if="result.active" class="btn-success">
                  Aktiv
                  </span>
                  <span v-else :class="'btn-danger'">
                  Aktiv emas
                  </span>
                </td>
                <td class="d-none d-md-table-cell">
                  <div v-if="result.createdBy">{{ result.createdBy.fullName }}</div>
                  <div v-if="result.createdDate">{{ result.createdDate }}</div>
                </td>
                <td class="d-none d-md-table-cell">
                  <div v-if="result.updatedBy">{{ result.updatedBy.fullName }}</div>
                  <div v-if="result.updatedDate">{{ result.updatedDate }}</div>
                </td>
                <td>
                  <div class="btn-group btn-group-sm">
                    <router-link :to="'/user/' + result.id" tag="button" class="btn btn-success" v-if="hasAction('USER_EDIT')">
                      <i class="fa fa-pencil"></i>
                    </router-link>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <div class="loader mt-5 mb-5" v-if="loading"></div>
              <span class="no-data mt-5 mb-5" v-if="!loading && results && results.data && results.data.length===0">Нет данных</span>
            </div>
            <div class="row" v-if="!loading && results">
              <div class="col-2 pt-3 pb-3 text-left">
                <div class="pl-3">
                  <b>Umumiy : <i><span>{{ results.total | currency('', 0, { thousandsSeparator: ' ' })}}</span></i></b>
                </div>
              </div>
              <div class="col-10 pt-3 pb-3">
                <b-pagination :total-rows="results ? results.total : 0" v-model="filter.page.page" :per-page="filter.page.size" @change="onPageChange"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BPagination} from 'bootstrap-vue';
import initFilterModel from "@/shared/filter";

export default {
  name: "UserList",
  components: {
    'b-pagination': BPagination
  },
  data() {
    return {
      loading: false,
      filter: initFilterModel(),
      results: null,
    }
  },
  methods: {
    initList() {
      this.loading = true;
      this.$http.post('user/list', this.filter)
          .then(res => {
            this.results = res.data;
            this.loading = false;
          }, err => {
            console.log(err);
            this.loading = false;
          });
    },
    onPageChange(page) {
      this.filter.page.page = page;
      this.initList();
    }
  },
  created() {
    this.initList();
  }
}
</script>

<style scoped>

</style>